import React, { useEffect, useMemo, useState, useCallback, memo } from "react";
import styled, { css } from "styled-components";
import sortBy from "lodash/sortBy";
import {
  typography,
  space,
  color,
  TypographyProps,
  SpaceProps,
  ColorProps,
} from "styled-system";
import isFunction from "lodash/isFunction";
import HelpPopover from "./HelpPopOver";
import {
  TableFilterRange,
  TableFilterRangeConfig,
  TableFilterRangeModal,
} from "./TableFilterRange";
import { FaFilter } from "react-icons/fa";
import {
  TableFilterDateRange,
  TableFilterDateRangeConfig,
  TableFilterDateRangeModal,
} from "./TableFilterDateRange";
import {
  TableFilterUnique,
  TableFilterUniqueConfig,
  TableFilterUniqueModal,
} from "./TableFilterUnique";
import {
  TableFilterSearch,
  TableFilterSearchConfig,
  TableFilterSearchModal,
} from "./TableFilterSearch";
import { usePagination } from "../utils/usePagination";
import { Box, Flex, InputWithSearch } from "..";
import { TablePaginationRow } from "./TablePaginationRow";
import { useDebounce } from "../utils/useDebounce";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:last-child td {
    border-bottom: 0;
  }
`;

type TdProps = SpaceProps & ColorProps & TypographyProps;

const Td = styled.td<TdProps>`
  font-size: 13px;
  border: 1px solid #ddd;
  padding: 6px 18px;
  vertical-align: middle;

  & > * {
    vertical-align: middle;
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  ${typography};
  ${space};
  ${color};
`;

Td.defaultProps = {
  fontSize: [0, 1],
  pt: [1, 1, 1],
  pb: [1, 1, 1],
};

type ThProps = SpaceProps &
  TypographyProps & {
    sortable?: boolean;
  };

const FilterCell = styled.th`
  text-align: center;
  padding: 3px;
  border-top: 1px solid ${(props) => props.theme.colors.grey5};
`;

const Th = styled.th<ThProps>`
  text-align: left;
  background-color: #fcfcfc;
  padding: 6px 18px;
  white-space: nowrap;
  font-weight: 600;
  ${typography};
  ${space};

  ${(props) =>
    props.sortable &&
    css`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `};
`;

Th.defaultProps = {
  fontSize: [0, 1],
  pt: [1, 1, 1],
  pb: [1, 1, 1],
};

const processProps = (props: object, item: any) => {
  if (props == null) {
    return {};
  }

  return Object.entries(props)
    .map(([key, value]) => {
      if (isFunction(value)) {
        return [key, value(item)];
      } else {
        return [key, value];
      }
    })
    .reduce((acc, val) => {
      acc[val[0]] = val[1];
      return acc;
    }, {} as any);
};

export type TableFilterConfig<T> =
  | TableFilterRangeConfig<T>
  | TableFilterDateRangeConfig<T>
  | TableFilterUniqueConfig<T>
  | TableFilterSearchConfig<T>;

export type SortableTableColumn<T> = {
  name?: string | null;
  label: React.ReactNode;
  filter?: TableFilterConfig<T>;
  format?: (item: T, index: number, self: T[]) => React.ReactNode;
  sortFormat?: (item: T) => string | number | Date | boolean;
  headingProps?: any;
  notSortable?: boolean;
  props?: any;
  hidden?: boolean;
  help?: string;
  exportLabel?: string;
  excludeFromExport?: boolean;
  exportFormat?: (item: T) => string;
  numeric?: boolean;
};

export type SortableTableProps<T> = {
  columns: SortableTableColumn<T>[];
  data: T[];
  defaultSort?: number | null;
  defaultDesc?: boolean;
  emptyMessage?: string;
  disableSort?: boolean;
  rowKey?: Extract<keyof T, string>;
  rowStyle?: (item: T) => any;
  disableIncrementalRender?: boolean;
  filterSaveKey?: string;
  paged?: boolean;
  pageSize?: number;
  rowSearch?: (item: T) => string;
  rowSearchPlaceholder?: string;
  onExport?: (data: Record<string, string>[]) => void;
  onRowClick?: (item: T, index: number, self: T[]) => void;
  compact?: boolean;
  afterSort?: (items: T[]) => T[];
};

type RowProps<T> = {
  data: T;
  rowKey: string | null | undefined;
  rowIndex: number;
  fullData: T[];
  rowStyle: (item: any) => any;
  columns: SortableTableColumn<any>[];
  onClick: (data: T, rowIndex: number, self: T[]) => void;
  isClickable: boolean;
  compact?: boolean;
};

const FilterButtonElem = styled.button<{ active: boolean }>`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.grey2};
  border: 0;
  background: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & svg {
    margin-left: 6px;
  }
`;

const FilterButton = ({
  onClick,
  active,
}: {
  onClick: () => void;
  active: boolean;
}) => (
  <FilterButtonElem onClick={onClick} active={active}>
    Filter
    <FaFilter />
  </FilterButtonElem>
);

const RowElem = styled.tr<{ isClickable: boolean }>`
  &:hover td {
    background-color: ${(props) => props.theme.colors.grey6};
  }
  ${(props) =>
    props.isClickable &&
    css`
      cursor: pointer;
    `}
`;

const Row = React.memo(
  <T,>({
    data,
    rowStyle,
    rowIndex,
    fullData,
    columns,
    onClick,
    isClickable,
    compact,
  }: RowProps<T>) => {
    const onRowClick = useCallback(() => {
      onClick(data, rowIndex, fullData);
    }, [data, onClick, rowIndex, fullData]);

    return (
      <RowElem
        style={rowStyle(data)}
        onClick={onRowClick}
        isClickable={isClickable}
      >
        {columns.map(
          (c, i) =>
            !c.hidden && (
              <Td
                key={i}
                px={[1, 1, compact ? 1 : 2]}
                textAlign={c.numeric ? "right" : undefined}
                {...processProps(c.props, data)}
              >
                {c.format
                  ? c.format(data, rowIndex, fullData)
                  : (data as any)[c.name ?? ""]}
              </Td>
            ),
        )}
      </RowElem>
    );
  },
);

const ident = () => ({});

export function SortableTable<T>({
  columns,
  data = [],
  rowKey,
  defaultSort = 0,
  defaultDesc = true,
  emptyMessage = "",
  disableSort = false,
  disableIncrementalRender = false,
  rowStyle = ident,
  filterSaveKey,
  rowSearch,
  rowSearchPlaceholder = "Search...",
  paged,
  onExport,
  pageSize = 50,
  onRowClick,
  compact,
  afterSort,
}: SortableTableProps<T>) {
  const [sort, setSort] = useState<number | null>(defaultSort);
  const [sortDir, setSortDir] = useState<boolean>(defaultDesc || false);
  const [renderCount, setRenderCount] = useState<number>(0);
  const [shownFilter, setShownFilter] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 300);

  if (!data) {
    data = [];
  }

  if (data.length < 20) {
    disableIncrementalRender = true;
  }

  const defaultFilters =
    filterSaveKey == null
      ? {}
      : JSON.parse(
          window.localStorage.getItem("table_filter_" + filterSaveKey) ?? "{}",
        );

  const [filterValues, setFilterValues] =
    useState<Record<string, any>>(defaultFilters);

  useEffect(() => {
    if (sort != null && columns[sort] == null) {
      setSort(defaultSort);
    }
  }, [sort, columns, defaultSort]);

  useEffect(() => {
    if (filterSaveKey != null) {
      const vals = window.localStorage.getItem("table_filter_" + filterSaveKey);
      if (vals != null) {
        setFilterValues(JSON.parse(vals));
      }
    }
  }, [filterSaveKey]);

  useEffect(() => {
    if (filterSaveKey != null) {
      window.localStorage.setItem(
        "table_filter_" + filterSaveKey,
        JSON.stringify(filterValues),
      );
    }
  }, [filterValues, filterSaveKey]);

  useEffect(() => {
    setRenderCount(0);
  }, [data, sort, sortDir, filterValues]);

  const setFilter = useCallback(
    (name: string) => (vals: any) => {
      setFilterValues((old) => ({
        ...old,
        [name]: vals,
      }));
      setShownFilter(null);
    },
    [],
  );

  useEffect(() => {
    const i = setInterval(() => {
      if (
        renderCount < (data?.length ?? 0) &&
        !disableIncrementalRender &&
        shownFilter == null
      ) {
        setRenderCount((old) => old + 50);
      }
    }, 2000 / 60);

    return () => {
      clearInterval(i);
    };
  }, [renderCount, data, disableIncrementalRender, shownFilter]);

  const doSort = useCallback(
    (index: number) => {
      if (sort === index) {
        setSortDir((x) => !x);
      } else {
        setSortDir(false);
        setSort(index);
      }
    },
    [sort],
  );

  const onRowClickCb = useCallback(
    (item: T, index: number, self: T[]) => {
      if (onRowClick != null) {
        onRowClick(item, index, self);
      }
    },
    [onRowClick],
  );

  let sorted = useMemo(() => {
    let sorted = [...(data ?? [])];

    if (sort != null && columns[sort] != null) {
      const sortedColumn = columns[sort];

      const sortKey = sortedColumn.sortFormat || sortedColumn.name;

      sorted = sortKey ? sortBy(data, sortKey) : [...data];

      if (sortDir) {
        sorted.reverse();
      }
    }

    Object.entries(filterValues)
      .filter((x) => x[1] != null)
      .map(([name, values]) => {
        const f = columns.find((x) => x.name === name)?.filter!;
        const fn =
          f.type === "range"
            ? TableFilterRange
            : f.type === "daterange"
              ? TableFilterDateRange
              : f.type === "unique"
                ? TableFilterUnique
                : f.type === "search"
                  ? TableFilterSearch
                  : null;
        if (fn != null) {
          sorted = sorted.filter((x) => fn(x, f as any, values));
        }
      });

    if (rowSearch && debouncedSearch.trim() !== "") {
      sorted = sorted.filter((x) =>
        rowSearch(x)
          .trim()
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase()),
      );
    }

    if (afterSort != null) {
      sorted = afterSort(sorted);
    }

    return sorted;
  }, [
    data,
    columns,
    filterValues,
    sortDir,
    sort,
    rowSearch,
    debouncedSearch,
    afterSort,
  ]);

  if (!disableIncrementalRender) {
    sorted = sorted.slice(0, renderCount);
  }

  const exportData = () => {
    const data: Record<string, string>[] = sorted.map((row, rowIndex, self) => {
      const entries = columns
        .filter((x) => !x.excludeFromExport)
        .map(({ label, exportLabel, name, exportFormat, format }) => {
          const byName = row[name as keyof T];
          const value = exportFormat
            ? exportFormat(row)
            : format
              ? format(row, rowIndex, self)?.toString()
              : byName;
          return [
            exportLabel ?? label?.toString() ?? "",
            String(value ?? ""),
          ] as const;
        });

      return Object.fromEntries(entries);
    });

    if (onExport) {
      onExport(data);
    }
  };

  const {
    activePage,
    data: pagedData,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
  } = usePagination(sorted, pageSize);

  const anyFilters = columns.some((x) => x.filter != null);
  const shownFilterColumn = columns.find((x) => x.name === shownFilter);
  const filter = shownFilterColumn?.filter;

  if (paged && !disableIncrementalRender) {
    throw new Error("A table cannot be paged and incrementally render");
  }

  const tableData = paged ? pagedData : sorted;

  return (
    <>
      {filter?.type === "range" ? (
        <TableFilterRangeModal
          data={data}
          config={filter}
          values={filterValues[shownFilter as string]}
          onChange={setFilter(shownFilter as string)}
        />
      ) : filter?.type === "daterange" ? (
        <TableFilterDateRangeModal
          data={data}
          config={filter}
          values={filterValues[shownFilter as string]}
          onChange={setFilter(shownFilter as string)}
        />
      ) : filter?.type === "unique" ? (
        <TableFilterUniqueModal
          data={data}
          config={filter}
          values={filterValues[shownFilter as string]}
          onChange={setFilter(shownFilter as string)}
        />
      ) : filter?.type === "search" ? (
        <TableFilterSearchModal
          data={data}
          config={filter}
          values={filterValues[shownFilter as string]}
          onChange={setFilter(shownFilter as string)}
        />
      ) : null}
      <Box>
        {(paged || rowSearch) && (
          <Flex
            p={1}
            justifyContent="space-between"
            flexDirection={["column", "row"]}
            backgroundColor="#fcfcfc"
            borderBottom={1}
          >
            {rowSearch ? (
              <Flex width={["100%", "300px"]} mb={[1, 0]}>
                <InputWithSearch
                  placeholder={rowSearchPlaceholder}
                  searching={search !== debouncedSearch}
                  width="100%"
                  ml={[0, 2]}
                  value={search}
                  onChange={(ev) => setSearch(ev.target.value)}
                />
              </Flex>
            ) : (
              <div />
            )}
            {paged && (
              <Flex justifyContent="flex-end">
                <TablePaginationRow
                  pageSize={pageSize}
                  itemCount={sorted.length}
                  activePage={activePage}
                  onNextPage={nextPage}
                  onPrevPage={prevPage}
                  hasPrevPage={hasPrevPage}
                  hasNextPage={hasNextPage}
                  onExport={onExport ? exportData : undefined}
                />
              </Flex>
            )}
          </Flex>
        )}
        <Box style={{ overflowX: "auto" }}>
          <Table>
            <thead>
              <tr>
                {columns.map(
                  (
                    {
                      label,
                      headingProps,
                      notSortable,
                      help,
                      hidden = false,
                      numeric = false,
                    },
                    i,
                  ) =>
                    !hidden && (
                      <Th
                        sortable={!disableSort && !notSortable}
                        px={[1, 1, compact ? 1 : 2]}
                        key={i}
                        textAlign={numeric ? "right" : undefined}
                        {...(headingProps || {})}
                        onClick={() =>
                          !disableSort && !notSortable && doSort(i)
                        }
                      >
                        {label}
                        {help && (
                          <HelpPopover
                            content={help}
                            ml={1}
                            style={{ verticalAlign: "middle" }}
                          />
                        )}
                        {sort === i && (sortDir ? " ▼" : " ▲")}
                      </Th>
                    ),
                )}
              </tr>
              {anyFilters && (
                <tr>
                  {columns.map(({ name, filter }) => (
                    <FilterCell key={name}>
                      {filter && (
                        <FilterButton
                          active={filterValues[name ?? ""] != null}
                          onClick={() => setShownFilter(name as string)}
                        />
                      )}
                    </FilterCell>
                  ))}
                </tr>
              )}
            </thead>
            <tbody>
              {tableData.map((data, i, self) => (
                <Row
                  key={rowKey ? (data[rowKey] as unknown as string) : i}
                  data={data}
                  rowIndex={i + pageSize * (activePage - 1)}
                  fullData={sorted}
                  rowStyle={rowStyle}
                  columns={columns}
                  rowKey={rowKey}
                  onClick={onRowClickCb as any}
                  isClickable={onRowClick != null}
                  compact={compact}
                />
              ))}
              {sorted.length === 0 && (
                <tr>
                  <Td colSpan={columns.length} textAlign="center">
                    {emptyMessage}
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  );
}

const genericMemo: <T>(component: T) => T = memo;

export default genericMemo(SortableTable);
