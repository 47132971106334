import { DepotDto, DepotTransferDto, VanRunDto } from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getVanRunsForAccount = (code: string) =>
  axios.get<VanRunDto[]>(`/api/v2/vanruns/${code}`).then(({ data }) => data);

export const useAccountVanRuns = (code: string) =>
  useQuery(["vanruns", code], () => getVanRunsForAccount(code));

export const getTransfers = () =>
  axios
    .get<DepotTransferDto[]>("/api/depot-transfers")
    .then(({ data }) => data);

export const updateTransfer = (spec: DepotTransferDto) =>
  axios.put(`/api/depot-transfers/${spec.id}`, spec);

export const createTransfer = (spec: DepotTransferDto) =>
  axios.post("/api/depot-transfers", spec);

export const deleteTransfer = (spec: DepotTransferDto) =>
  axios.delete(`/api/depot-transfers/${spec.id}`);

export const getDepots = () =>
  axios.get<DepotDto[]>("/api/v2/depots").then(({ data }) => data);

export const updateDepot = (depot: DepotDto) =>
  axios.put(`/api/v2/depots/${depot.id}`, depot);

export const createDepot = (depot: DepotDto) =>
  axios.post(`/api/v2/depots`, depot);

export const deleteDepot = (depot: DepotDto) =>
  axios.delete(`/api/v2/depots/${depot.id}`);

export const useDepotTransfers = () =>
  useQuery(["depot-transfers"], getTransfers);

export const useDeleteDepotTransfer = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: DepotTransferDto) => deleteTransfer(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["depot-transfers"]);
      },
    },
  );

  return mutation;
};

export const useUpdateDepotTransfer = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: DepotTransferDto) => updateTransfer(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["depot-transfers"]);
      },
    },
  );

  return mutation;
};

export const useCreateDepotTransfer = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: DepotTransferDto) => createTransfer(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["depot-transfers"]);
      },
    },
  );

  return mutation;
};

export const useDepots = () => useQuery(["depots"], getDepots);

export const useUpdateDepot = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: DepotDto) => updateDepot(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["depots"]);
      },
    },
  );

  return mutation;
};

export const useCreateDepot = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: DepotDto) => createDepot(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["depots"]);
      },
    },
  );

  return mutation;
};

export const useDeleteDepot = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: DepotDto) => deleteDepot(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["depots"]);
      },
    },
  );

  return mutation;
};
