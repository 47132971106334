import { BasketOrderDto, CamStatuses } from "@oaktyres/model";
import {
  LoadedBasketItem,
  useAccountNew,
  useCurrentBalances,
  usePlaceOrder,
} from "@oaktyres/queries";
import {
  Alert,
  Button,
  currencyTypeNames,
  Flex,
  formatCurrency,
  FormInput,
  MiniTable,
  Modal,
  Panel,
  ProductModal,
  Text,
  TyreModal,
  useAuth,
} from "@oaktyres/ui";
import { sum } from "lodash";
import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { order } from "styled-system";
import { useScopedBasket } from "../../basket";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { useAvailableCurrencies } from "../../hooks/accounts";
import {
  LoadedBasketItemDisplay,
  LoadingBasketItemDisplay,
} from "./BasketItem";
import { DeliveryAddressSelector } from "./DeliveryAddressSelector";

const TotalsTable = styled.table`
  font-size: 14px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey3};
  margin-bottom: 24px;
  font-variant-numeric: tabular-nums;

  th {
    font-weight: 500;
    text-align: left;
  }

  tr.sum {
    th,
    td {
      font-weight: 600;
    }
  }

  td:last-child {
    text-align: right;
  }
`;

const OnStopModal = ({ onClose }: { onClose: () => void }) => (
  <Modal shown onClose={onClose} width={500}>
    <Text fontSize={3} fontWeight={600} mb={1} color="#222">
      This order can’t currently be fulfilled.
    </Text>
    <Text mb={3}>
      Please contact our accounts team on <strong>8444 93 8001</strong> to
      discuss your account’s terms.
    </Text>
    <Button onClick={onClose}>Ok</Button>
  </Modal>
);

export function BasketPage() {
  const { addToBasket, items, clearBasket, updateItem, refreshBasket } =
    useScopedBasket();
  const { user } = useAuth();
  const [accountCode] = useScopedAccount();
  const [orderNum, setOrderNum] = useState<string>("");
  const [regNum, setRegNum] = useState<string>("");
  const account = useAccountNew(accountCode);
  const [showModal, setShowModal] = useState<string | null>(null);
  const [checkingOut, setCheckingOut] = useState(false);
  const history = useHistory();
  const [showOnStopModal, setShowOnStopModal] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState<number | null>(null);
  const [showProductModal, setShowProductModal] = useState<
    [string, string] | null
  >(null);
  const placeOrder = usePlaceOrder();
  const balances = useCurrentBalances(accountCode);
  const availableCurrencies = useAvailableCurrencies(accountCode);

  useEffect(() => {
    refreshBasket();
  }, []);

  const midasId = account.data?.midasId;
  const isMidasAvailable = account.data?.isMidasAvailable ?? false;
  const requireOrderNumber = account.data?.requiresOrderNumber ?? false;
  const requireReg = account.data?.requiresRegNumber ?? false;

  const anyNotNextVan = items
    .filter((x): x is LoadedBasketItem => x.status === "loaded")
    .some((x) => x.data.availability.every((x) => !x.isNextRun));

  const onInc = (code: string) => {
    const item = items.find((x) => x.code === code);
    if (item?.status === "loaded") {
      const maxQty = sum(item.data.availability.map((x) => x.qtyAvailable));
      updateItem(code, Math.min(item.qty + 1, maxQty));
    }
  };
  const onDec = (code: string) => {
    const qty = items.find((x) => x.code === code)?.qty;
    if (qty != null && qty > 1) {
      updateItem(code, qty - 1);
    }
  };

  const onRemove = (code: string) => {
    if (window.confirm("Are you sure?")) {
      updateItem(code, 0);
    }
  };

  const totalGbp = sum(
    items
      .filter((x) => x.status === "loaded" && x.data.price?.currency === "gbp")
      .map((x) =>
        x.status === "loaded" ? x.qty * (x.data.price?.value ?? 0) : 0,
      ),
  );

  const totalDsp = sum(
    items
      .filter((x) => x.status === "loaded" && x.data.price?.currency === "dsp")
      .map((x) =>
        x.status === "loaded" ? x.qty * (x.data.price?.value ?? 0) : 0,
      ),
  );

  const clear = () => {
    if (window.confirm("Are you sure?")) {
      clearBasket();
    }
  };

  const checkout = () => {
    const accountIsOnStop = account.data?.status !== CamStatuses.Active;

    if (accountIsOnStop) {
      setShowOnStopModal(true);
      return;
    }

    const availableDsp =
      account.data!.dealerSupportPoints.overdraft +
      (balances.data!.find((x) => x.type === "dsp")?.value ?? 0);

    const minimumDsp =
      account.data!.orderMinimums.find((x) => x.currency === "dsp")?.value ?? 0;

    const orderDsp = items
      .filter((x): x is LoadedBasketItem => x.status === "loaded")
      .filter((x) => x.data.price?.currency === "dsp")
      .reduce((acc, val) => acc + (val.data.price?.value ?? 0) * val.qty, 0);

    if (orderDsp > 0 && orderDsp > availableDsp) {
      return window.alert("Insufficient DSP for order");
    }

    if (orderDsp > 0 && orderDsp < minimumDsp) {
      return window.alert(
        `Order DSP value is less than the minimum of ${minimumDsp}`,
      );
    }

    if (!window.confirm("Are you sure you want to checkout?")) {
      return;
    }

    setCheckingOut(true);

    const spec: BasketOrderDto = {
      accountCode: accountCode as string,
      regNumber: regNum,
      customerOrderNumber: orderNum,
      source: "portal",
      lines: items.map((x) => ({
        stockCode: x.code,
        quantity: x.qty,
      })),
      addressId: deliveryAddress,
    };

    placeOrder
      .mutateAsync(spec)
      .then((id) => {
        clearBasket();
        history.push(`/checkout/${id}`);
      })
      .catch((err) => {
        window.alert(err.response?.data?.message?.error ?? "Unknown Error");
      })
      .finally(() => {
        setCheckingOut(false);
      });
  };

  const anyUnavailableItems = items.some(
    (x) =>
      x.status === "loaded" &&
      x.qty >
        x.data.availability
          .map((x) => x.qtyAvailable)
          .reduce((acc, val) => acc + val, 0),
  );

  const canOrder =
    !balances.isLoading &&
    items.length > 0 &&
    midasId &&
    // temp
    //isMidasAvailable &&
    (orderNum.trim().length > 0 || !requireOrderNumber) &&
    (regNum.trim().length > 0 || !requireReg) &&
    items.every(
      (x) =>
        x.status === "loaded" &&
        x.qty <=
          x.data.availability
            .map((x) => x.qtyAvailable)
            .reduce((acc, val) => acc + val, 0),
    );

  // Limit to DSP only for now
  const orderMinimums = (account.data?.orderMinimums ?? [])
    .filter((x) => availableCurrencies.includes(x.currency))
    .filter((x) => x.currency === "dsp");

  return (
    <Flex maxWidth="1600px" width="100%">
      {showOnStopModal && (
        <OnStopModal onClose={() => setShowOnStopModal(false)} />
      )}
      {showModal && (
        <TyreModal
          stockCode={showModal}
          onClose={() => setShowModal(null)}
          accountCode={accountCode}
          basketItems={items}
          onAddToBasket={(count) => addToBasket(showModal, count)}
        />
      )}
      {showProductModal && (
        <ProductModal
          productId={showProductModal[0]}
          variantId={showProductModal[1]}
          accountCode={accountCode}
          onClose={() => setShowProductModal(null)}
          basketItems={items}
          onAddToBasket={(code, count) => addToBasket(code, count)}
        />
      )}
      <Panel p={3} width="100%">
        {anyNotNextVan && (
          <Alert icon={FaExclamationTriangle} color="info" mb={3}>
            <Text fontWeight={600} fontSize={2}>
              Caution
            </Text>
            <Text fontWeight={500} fontSize={1}>
              Some items on this order will be dispatched on a van run that is
              later than your next scheduled van run. See dispatch times below.
            </Text>
          </Alert>
        )}
        {anyUnavailableItems && (
          <Alert icon={FaExclamationTriangle} color="danger" mb={3}>
            <Text fontWeight={600} fontSize={2}>
              Caution
            </Text>
            <Text fontWeight={500} fontSize={1}>
              Some items in your basket are no longer available. Please remove
              the offending items to continue.
            </Text>
          </Alert>
        )}
        <Flex flexDirection="column">
          {items.length === 0 && (
            <Text textAlign="center" color="grey3" fontSize={4} mb={3}>
              Basket Empty
            </Text>
          )}
          {items.map((x) =>
            x.status === "loaded" ? (
              <LoadedBasketItemDisplay
                key={x.code}
                item={x}
                showRewardsPanel={user?.type !== "account"}
                onIncrement={() => onInc(x.code)}
                onDecrement={() => onDec(x.code)}
                onRemove={() => onRemove(x.code)}
                onShowModal={() => {
                  if (x.data.type === "tyre") {
                    setShowModal(x.code);
                  } else {
                    setShowProductModal([x.data.productId!, x.code]);
                  }
                }}
              />
            ) : (
              <LoadingBasketItemDisplay item={x} key={x.code} />
            ),
          )}
        </Flex>
        <Flex flexDirection={["column", "row"]} justifyContent="space-between">
          <Flex width={[1, 1 / 4]} flexDirection="column">
            <DeliveryAddressSelector
              account={account.data ?? null}
              value={deliveryAddress}
              onChange={setDeliveryAddress}
            />
            {orderMinimums?.length > 0 && (
              <Panel
                width={["100%", "300px"]}
                p={2}
                boxShadow={"none"}
                border={1}
              >
                <Text fontWeight={600} fontSize={1} color="black" mb={2}>
                  Minimum Order Values
                </Text>
                <Text fontSize={1} color="grey1" mb={2}>
                  For each currency, a minimum order value is required as shown
                  below:
                </Text>
                <MiniTable>
                  <tbody>
                    {account.data?.orderMinimums
                      .filter((x) => x.currency === "dsp")
                      .map((x) => (
                        <tr key={x.currency}>
                          <td>{currencyTypeNames[x.currency]}</td>
                          <td style={{ textAlign: "right" }}>
                            {formatCurrency(x.currency)(x.value)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </MiniTable>
              </Panel>
            )}
          </Flex>
          <Flex
            width={[1, 2 / 4, 2 / 4, 1 / 4]}
            flexDirection="column"
            alignItems="stretch"
          >
            <TotalsTable>
              <tbody>
                <tr>
                  <th>Account Subtotal</th>
                  <td>{formatCurrency("gbp")(totalGbp)}</td>
                </tr>
                {totalDsp > 0 && (
                  <tr>
                    <th>DSP Subtotal</th>
                    <td>{formatCurrency("dsp")(totalDsp)}</td>
                  </tr>
                )}
                <tr style={{ display: "none" }}>
                  <th>Consumables Credit</th>
                  <td>
                    {(-0).toLocaleString("en-GB", {
                      style: "currency",
                      currency: "gbp",
                    })}
                  </td>
                </tr>
                <tr className="sum">
                  <th>Account Total</th>
                  <td>
                    {totalGbp.toLocaleString("en-GB", {
                      style: "currency",
                      currency: "gbp",
                    })}
                  </td>
                </tr>
              </tbody>
            </TotalsTable>
            <Flex flexDirection="column">
              <FormInput
                label="Customer Order Number"
                value={orderNum}
                onChange={(ev) => setOrderNum(ev.target.value)}
                width={1}
                maxLength={16}
                mb={1}
              />
              {requireOrderNumber && (
                <Text color="danger" fontSize={1}>
                  Required
                </Text>
              )}
              <FormInput
                mt={2}
                label="Registration Number"
                value={regNum}
                onChange={(ev) => setRegNum(ev.target.value)}
                width={1}
                maxLength={8}
                mb={1}
              />
              {requireReg && (
                <Text color="danger" fontSize={1}>
                  Required
                </Text>
              )}
            </Flex>
            <Flex justifyContent="flex-end" mt={3} mb={2}>
              <Button
                color="danger"
                onClick={clear}
                mr={2}
                disabled={items.length === 0}
              >
                Clear Basket
              </Button>
              <Button
                icon={FaCheckCircle}
                disabled={!canOrder || checkingOut}
                onClick={checkout}
              >
                Complete Order
              </Button>
            </Flex>
            {false && account.data != null && !isMidasAvailable && (
              <Text color="danger" fontSize={1}>
                Midas unavailable for account.
              </Text>
            )}
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
}
